import { callManagementRoles, callRecordsRoles } from '@/defaults/roles';

export default [
  {
    path: '/call-records',
    name: 'call-records',
    component: () => import('@/pages/CallRecord/CallRecords.vue'),
    meta: { title: 'Call Records', roles: callRecordsRoles },
  },
  {
    path: '/call-records/management',
    name: 'call-records.management',
    component: () => import('@/pages/CallRecord/CallsManagement.vue'),
    meta: { title: 'Calls management', roles: callManagementRoles },
  },
];
