import { UserRole } from '@/consts';

const allRoles = [
  UserRole.Developer,
  UserRole.SuperAdmin,
  UserRole.Admin,
  UserRole.Reviewer,
  UserRole.Caller,
  UserRole.Preparer,
  UserRole.Client,
];

const superAdminRoles = [UserRole.Developer, UserRole.SuperAdmin];

const adminRoles = [UserRole.Developer, UserRole.SuperAdmin, UserRole.Admin];

const employeeRoles = [UserRole.Reviewer, UserRole.Caller, UserRole.Preparer];

const callRecordsRoles = [
  UserRole.Developer,
  UserRole.SuperAdmin,
  UserRole.Admin,
  UserRole.Caller,
];

const callManagementRoles = [
  UserRole.Developer,
  UserRole.SuperAdmin,
  UserRole.Admin,
];

const dmsRoles = [
  UserRole.Developer,
  UserRole.SuperAdmin,
  UserRole.Admin,
  UserRole.Reviewer,
  UserRole.Caller,
  UserRole.Preparer,
];

const trackerRoles = [
  UserRole.Developer,
  UserRole.SuperAdmin,
  UserRole.Admin,
  UserRole.Reviewer,
  UserRole.Caller,
  UserRole.Preparer,
];

const taxpayerRoles = [
  UserRole.Developer,
  UserRole.SuperAdmin,
  UserRole.Admin,
  UserRole.Reviewer,
  UserRole.Preparer,
  UserRole.Client,
];

const userModuleRoles = [
  UserRole.Developer,
  UserRole.SuperAdmin,
  UserRole.Caller,
];

export {
  allRoles,
  superAdminRoles,
  adminRoles,
  employeeRoles,
  callRecordsRoles,
  callManagementRoles,
  dmsRoles,
  trackerRoles,
  taxpayerRoles,
  userModuleRoles,
};
