<script setup lang="ts">
import { computed } from 'vue';

import type { User } from '@/types/user';

type UserCardProps = {
  user: User;
  avatar?: boolean;
  name?: boolean;
  email?: boolean;
  tooltip?: string;
  hideTooltip?: boolean;
  tooltipPlacement?: 'top' | 'bottom' | 'left' | 'right';
  size?: 'xs' | 'sm' | 'md' | 'lg';
};

// Props
const {
  user,
  size = 'xs',
  name = false,
  avatar = false,
  email = false,
  tooltip = '',
  hideTooltip = false,
  tooltipPlacement = 'bottom',
} = defineProps<UserCardProps>();

// Computed Properties
const avatarBackgroundColor = computed(() => {
  return user.color_code || '#5243aa';
});

const fullName = computed(() =>
  `${user?.first_name} ${user?.last_name}`.trim()
);

const avatarSize = computed(() => {
  return {
    xs: 'w-6 h-6 min-w-6 max-w-6',
    sm: 'w-8 h-8 min-w-8 max-w-8',
    md: 'w-10 h-10 min-w-10 max-w-10',
    lg: 'w-12 h-12 min-w-12 max-w-12',
  }[size];
});

const tooltipContent = computed(() => {
  return hideTooltip ? '' : tooltip || fullName.value;
});
</script>

<template>
  <div class="flex items-center space-x-2">
    <div v-if="avatar" class="w-max">
      <Tippy
        :content="tooltipContent"
        :options="{ placement: tooltipPlacement }"
      >
        <img
          v-if="user?.avatar"
          :class="`${avatarSize} rounded-full`"
          :src="user.avatar"
          :alt="fullName"
        />

        <div
          v-else-if="user?.initials"
          :class="`${avatarSize} flex items-center justify-center rounded-full`"
          :style="{ backgroundColor: avatarBackgroundColor }"
        >
          <div
            class="text-sm font-medium leading-none text-white"
            :class="{ 'text-xs': size === 'xs' }"
          >
            {{ user.initials }}
          </div>
        </div>
      </Tippy>
    </div>

    <div v-if="name || email" class="flex flex-col flex-1">
      <div v-if="name">{{ fullName }}</div>
      <div v-if="email && user?.email" class="flex items-center gap-1">
        <div class="text-xs text-gray-500 truncate">{{ user.email }}</div>
        <ClickToCopy title="Copy Email" :content="user.email" />
      </div>
    </div>
  </div>
</template>
