import { SideMenuLayout } from '@/themes';

import accountRoutes from './modules/account';
import authRoutes from './modules/auth';
import callCategoryRoutes from './modules/call-category';
import callRecordRoutes from './modules/call-record';
import callSlotRoutes from './modules/call-slot';
import dmsRoutes from './modules/dms';
import documentTypeRoutes from './modules/document-type';
import errorRoutes from './modules/error';
import optionRoutes from './modules/option';
import roleRoutes from './modules/role';
import taxDocumentRoutes from './modules/tax-document';
import taxpayerRoutes from './modules/taxpayer';
import trackerRoutes from './modules/tracker';
import userRoutes from './modules/user';

export default [
  {
    path: '/',
    component: SideMenuLayout,
    children: [
      {
        path: '/',
        name: 'dashboard',
        component: () => import('@/pages/Dashboard/Dashboard.vue'),
        meta: { title: 'Dashboard' },
      },
      ...callCategoryRoutes,
      ...callRecordRoutes,
      ...callSlotRoutes,
      ...dmsRoutes,
      ...documentTypeRoutes,
      ...optionRoutes,
      ...roleRoutes,
      ...taxDocumentRoutes,
      ...taxpayerRoutes,
      ...trackerRoutes,
      ...userRoutes,
      accountRoutes,
    ],
  },
  authRoutes,
  // 404 route should be at the end
  ...errorRoutes,
];
