import { UserRole } from '@/consts';
import { taxpayerRoles } from '@/defaults/roles';

export default [
  {
    path: '/taxpayers',
    name: 'taxpayers',
    component: () => import('@/pages/Taxpayer/Taxpayers.vue'),
    meta: { title: 'Taxpayers', roles: taxpayerRoles },
  },
  {
    path: '/taxpayers/create',
    name: 'taxpayers.create',
    component: () => import('@/pages/Taxpayer/CreateTaxpayer.vue'),
    meta: { title: 'Add Taxpayer', roles: [UserRole.Client] },
  },
  {
    path: '/taxpayers/:taxpayerId/edit',
    name: 'taxpayers.edit',
    component: () => import('@/pages/Taxpayer/EditTaxpayer.vue'),
    meta: { title: 'Edit Taxpayer Details', roles: [UserRole.Client] },
  },
  {
    path: '/taxpayers/:taxpayerId',
    name: 'taxpayers.view',
    component: () => import('@/pages/Taxpayer/ViewTaxpayer.vue'),
    meta: { title: 'Taxpayer Details', roles: taxpayerRoles },
  },
];
