<script setup lang="ts">
import {
  computed,
  type SelectHTMLAttributes,
  onMounted,
  inject,
  ref,
  useAttrs,
} from 'vue';
import TomSelectPlugin from 'tom-select';
import omit from 'lodash/omit';

import '@/assets/css/vendors/tom-select.css';
import { FormError, FormLabel } from '../Form';
import { setValue, init, updateValue } from './tom-select';
// @ts-ignore
import type { TomSettings, RecursivePartial } from 'tom-select/src/types';

defineOptions({ inheritAttrs: false });

export interface TomSelectElement extends HTMLSelectElement {
  TomSelect: TomSelectPlugin;
}

export interface TomSelectProps extends /* @vue-ignore */ SelectHTMLAttributes {
  modelValue: string | string[];
  options?: RecursivePartial<TomSettings>;
  refKey?: string;
  label?: string;
  error?: string;
  required?: boolean;
  // Array of strings, numbers, or objects with customizable keys
  items?: Array<string | number | { [key: string]: any }>;
  labelKey?: string;
  valueKey?: string;
}

export interface TomSelectEmit {
  (e: 'update:modelValue', value: string | string[]): void;
  (e: 'optionAdd', value: string | number): void;
}

export type ProvideTomSelect = (el: TomSelectElement) => void;

const props = withDefaults(defineProps<TomSelectProps>(), {
  required: false,
  labelKey: 'label',
  valueKey: 'id',
});

const emit = defineEmits<TomSelectEmit>();

const tomSelectRef = ref<TomSelectElement>();
const attrs = useAttrs();

// Compute all default options
const computedOptions = computed(() => {
  let options: TomSelectProps['options'] = {
    ...props.options,
    plugins: {
      dropdown_input: {},
      ...props.options?.plugins,
    },
  };

  if (Array.isArray(props.modelValue)) {
    options = {
      persist: false,
      create: true,
      onDelete: function (values: string[]) {
        return confirm(
          values.length > 1
            ? `Are you sure you want to remove these ${values.length} items?`
            : `Are you sure you want to remove "${values[0]}"?`
        );
      },
      ...options,
      plugins: {
        remove_button: { title: 'Remove this item' },
        ...options.plugins,
      },
    };
  }

  return options;
});

const vSelectDirective = {
  mounted(el: TomSelectElement) {
    // Unique attribute
    el.setAttribute('data-id', '_' + Math.random().toString(36).substr(2, 9));

    // Clone the select element to prevent tom select remove the original element
    const clonedEl = el.cloneNode(true) as TomSelectElement;

    // Save initial class names
    const classNames = el?.getAttribute('class');
    classNames && clonedEl.setAttribute('data-initial-class', classNames);

    // Hide original element
    el?.parentNode && el?.parentNode.appendChild(clonedEl);
    el.setAttribute('hidden', 'true');

    // Initialize tom select
    setValue(clonedEl, props);
    init(el, clonedEl, props, computedOptions.value, emit);
  },
  updated(el: TomSelectElement) {
    const clonedEl = document.querySelectorAll(
      `[data-id='${el.getAttribute('data-id')}'][data-initial-class]`
    )[0] as TomSelectElement;
    const value = props.modelValue;
    updateValue(el, clonedEl, value, props, computedOptions.value, emit);
  },
};

const bindInstance = (el: TomSelectElement) => {
  if (props.refKey) {
    const bind = inject<ProvideTomSelect>(`bind[${props.refKey}]`);
    if (bind) {
      bind(el);
    }
  }
};

onMounted(() => {
  if (tomSelectRef.value) {
    bindInstance(tomSelectRef.value);
  }
});
</script>

<template>
  <div :class="attrs.class">
    <FormLabel
      v-if="props.label"
      :htmlFor="attrs.id"
      :text="props.label"
      :required="props.required"
    />

    <select
      ref="tomSelectRef"
      v-select-directive
      :value="props.modelValue"
      class="tom-select border-slate-300 !rounded-md box"
      v-bind="omit(attrs, 'class')"
      @change="
        event => {
          emit('update:modelValue', (event.target as HTMLSelectElement).value);
        }
      "
    >
      <!-- Default slot for options -->
      <slot :items="props.items">
        <option
          v-for="option in props.items"
          :key="typeof option === 'object' ? option[props.valueKey] : option"
          :value="typeof option === 'object' ? option[props.valueKey] : option"
        >
          {{ typeof option === 'object' ? option[props.labelKey] : option }}
        </option>
      </slot>
    </select>

    <FormError v-if="props.error" :message="props.error" />
  </div>
</template>
