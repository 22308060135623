import { UserStatus } from '@/consts';
import { User, UserFilters, UserForm } from '@/types/user';

const defaultFilters: UserFilters = {
  search: '',
  status: '',
  role: '',
  caller: '',
  preparer: '',
};

const defaultForm: UserForm = {
  first_name: '',
  last_name: '',
  email: '',
  phone_number: '',
  alternate_phone_number: '',
  timezone: '',
  zip_code: '',
  state: '',
  referral_code: '',
  referred_by: '',
  role: '',
  status: UserStatus.Active,
};

const headers = [
  { title: 'Actions', field: 'actions' },
  {
    title: 'ID',
    field: 'id',
    classList: 'w-32',
    type: 'number',
    sortable: true,
  },
  { title: 'Name', field: 'name', sortable: true, path: 'first_name' },
  { title: 'Caller', field: 'caller' },
  { title: 'Preparer', field: 'preparer' },
  { title: 'Email', field: 'email', sortable: true },
  { title: 'Phone Number', field: 'phone_number', sortable: true },
  { title: 'Address', field: 'address' },
  { title: 'Roles', field: 'roles' },
  { title: 'Status', field: 'status', sortable: true },
  { title: 'Last Login', field: 'last_login_at' },
  { title: 'Referral Code', field: 'referral_code', sortable: true },
  { title: 'Referred By', field: 'referrer', sortable: true },
  { title: 'Created By', field: 'creator', sortable: true },
  { title: 'Created At', field: 'created_at', sortable: true, type: 'date' },
  { title: 'Updated At', field: 'updated_at', sortable: true, type: 'date' },
  { title: 'Deleted At', field: 'deleted_at', sortable: true, type: 'date' },
];

const searchableFields: (keyof User)[] = [
  'id',
  'first_name',
  'last_name',
  'email',
  'phone_number',
  'alternate_phone_number',
  'zip_code',
  'state',
  'referral_code',
  'referred_by',
];

const defaultChangeUserPasswordForm = {
  current_password: '',
  password: '',
  password_confirmation: '',
};

export {
  defaultChangeUserPasswordForm,
  defaultFilters,
  defaultForm,
  headers,
  searchableFields,
};
